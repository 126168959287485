@font-face {
  font-family: Futura142;
  src: url('../../assets/font/tt0142m.ttf');
  // font-display: fallback;
}

@font-face {
  font-family: Futura143;
  src: url('../../assets/font/tt0143m.ttf');
  // font-display: fallback;
}

@font-face {
  font-family: Futura144;
  src: url('../../assets/font/tt0144m.ttf');
  // font-display: fallback;
}

@font-face {
  font-family: Futura145;
  src: url('../../assets/font/tt0145m.ttf');
  // font-display: fallback;
}

@font-face {
  font-family: FuturaBook;
  src: url('../../assets/font/FuturaBook.ttf');
  // font-display: fallback;
}

@font-face {
	font-family:SFDisplay;
	src:url('../../assets/font/SF\ Pro\ Display\ Regular\ font.ttf');
	/* font-weight:500; */
}
@font-face {
	font-family:SFBold;
	src:url('../../assets/font/SF-Pro-Display-Bold.otf');
}

@import "../../style/index.scss";
.home{
  width: 100%;
  overflow: hidden;
  background: url(../../assets/image/bg.png) no-repeat center;
  background-size: cover;
  .main{
    min-height: calc(100vh - 1.6rem);
    display: flex;
    flex-direction: column;
    .header{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
      .header-con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .58rem 0;
        .logo{
          width: 2.97rem;
          height: 1.2rem;
        }
        .arrow{
          width: 1.8rem;
          height: .8rem;
          font-size: 0;
        }
      }
    }
    .body{
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .photo-big{
        max-width: 18rem;
        // min-width: 700px;
        margin: auto;
      }

      .title{
        font-size: 75px;
        line-height: 1.1;
        font-family: Futura144;
        margin: 0;
      }
      .subtitle{
        font-size: 50px;
        line-height: 1.1;
        font-family: Futura144;
        margin: 0;
        margin-top: 20px;
      }
      .photo-small{
        position: absolute;
        width: 12%;
        bottom: 5%;
        left: 36%;
      }
    }
   
  }
  .footer{
    height: 1.6rem;
    position: relative;
    display: flex;
    align-items: center;
    .footer-bg{
      position: absolute;
      width: 25.7536rem;
      height: 1.7536rem;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      background: url(../../assets/image/footerBg.png) no-repeat center;
      background-size: cover;
    }
    .footer-con{
          display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      position: relative;
      z-index: 2;
      font-family: Futura142;
      .leftFooter{
        margin: 0;
       padding: 0;
       margin: -.04rem 0;
        a,span{
          font-size: .2rem;
          line-height: .28rem;
          padding: .04rem 0;
          display: block;
        }
        span{
          color: rgba($color: #000000, $alpha: .4);
        }
      }
      .socialList{
       margin: 0;
       padding: 0;
       display: flex;
       a{
        width: .8rem;
        height: .8rem;
        font-size: 12px;
        display: block;
        margin: 0 .18rem;
        font-family: Futura142;
       }
      }
    }
  }
}


@media (min-width: 1200px) and (max-width: 1400px){
  .home .main .body .title{
    text-align: left!important;
    font-size: 70px;
  }
}


@media (min-width: 976px) and (max-width: 1200px){
  .home .main .body .title{
    // font-size: 70px;
    // margin-bottom: 1rem;
    margin-top: 1.2rem;
  }
  .home {
    .footer{
      height: auto;
      padding: .8rem;
      .footer-con{
        .leftFooter{
          a,span{
            font-size: 14px;
            line-height: 1.1;
            display: block;
          }
        }
         .socialList a{
           width: 1.2rem;
           height: 1.2rem;
         }
      }
    }
  }
}

@media (min-width: 1200px) {
  .main .img-row{
    padding: 0 .7rem!important;
  }
  .home .main .body .photo-big{
    margin-right: -1.5rem;
    margin-left: -2rem;
    margin-bottom: -1.5rem;
    // padding-left: 1.5rem!important;
  }
  .home .main .body .subtitle{
    text-align: left!important;
  }
}

@media only screen and (max-width: 1200px){
  .home .main .body .title span{
    display: none;
  }
  .home .main .body .subtitle span{
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .home{
    background-image: url(../../assets/image/bg_ipad.png);
  }
  .home .main .body{
    flex-direction: column;
  }
  .home .main .body .photo-small{
    position:static;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    transform: translateX(-140%);
  }
}

@media (min-width: 768px) and (max-width: 900px){
  .home{
    .main{
      .body{
        .title{
          font-size: 70px;
          line-height: 1.1;
        }
      }
    }
    .footer{
      height: auto;
      padding: .8rem;
      .footer-con{
        .leftFooter{
          margin: -.1rem 0;
          a,span{
            font-size: .34rem;
            line-height: 1.1;
            padding: .1rem 0;
            display: block;
          }
        }
         .socialList a{
           width: 1.2rem;
           height: 1.2rem;
         }
      }
    }
  }   
}


@media (min-width: 576px) and (max-width: 768px){
  .home{
    .main{
      min-height: auto;

      .header {
        .header-con{
          padding: .5rem;
          .logo{
            height: 2rem;
            width: auto;
          }
          .arrow{
            height: 1.4rem;
            width: auto;
          }
        }
      }
    }
    .footer{
      height: auto;
      padding: .8rem 0;
      .footer-con{
        .leftFooter{
          margin: -.1rem 0;
          a,span{
            font-size: .34rem;
            line-height: 1.1;
            padding: .1rem 0;
            display: block;
          }
        }
         .socialList a{
           width: 1.2rem;
           height: 1.2rem;
         }
      }
    }
  }
  .home .main .body{
    flex-direction: column;
  }
  .home .main .body .photo-small{
    position:static;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    transform: translateX(-140%);
  }
  .home .main .body .title{
    font-size: 65px;
    margin: 1.3rem 0 0rem;
  }

  .home .main .body .subtitle{
    font-size: 35px;
  }
}


@media (max-width: 576px) { 
  .container{
    padding: 0 15px!important;
  }
  .home{
    background-image: url(../../assets/image/bg_iphone.png);
    .main{
      min-height: auto;
      .header{
        .header-con{
          padding: .3rem 0;
          .logo{
            width: auto;
            height: .8rem;
          }
          .arrow{
            width: auto;
            height: .55rem;
            font-size: 0;
          }
        }
      }
      .body{
        align-items: flex-start;
        .title{
          font-size: .8rem;
          line-height: 1.1;
          margin: 0.8rem 0 0rem;
        }
        .subtitle{
          font-size: .6rem;
        }
      }
    }
    .footer{
      height: auto;
      .footer-con{
        flex-direction: column;
        .leftFooter{
          span{
            margin-top: .5rem;
          }
        }
        .socialList{
          order: -1;
          margin-top: .6rem;
        }
      }
    }
  }  
  .home .main .body .photo-small{
    text-align: left;
    position:static;
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
    padding-left: .7rem;
    box-sizing: content-box;
  }
  .home .footer .footer-bg{
    width:100%;
    background-image: url(../../assets/image/footerBg-mobile.png);
    background-size: 100%;
  }
}

iframe {
  display: none;
}

html {
  font-size: 62.5%;
}

html,body{
  height: 100%;
}


@media screen and (min-width: 1900px) {
  .container {
      max-width: 1600px;
  }
}

@media (min-width: 1400px) and (max-width: 1900px){
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1240px;
  } 
}

@media (min-width: 1200px) and (max-width: 1400px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1180px;
  }
}

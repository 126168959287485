
@import "../../style/index.scss";


.service{
  // padding-bottom: 50px;
  padding: 20px 5px;
  padding-top: 0;
  background-color: #1a1a1a;
  .title{
    text-align: left;
    font-size: .48rem;
    line-height: .6rem;
    margin: 0;
    padding: .52rem 0 .28rem;
    margin-bottom: .38rem;
    font-family: SFBold;
    color: #fff;
  }
  .date{
    font-size: .4rem;
    line-height: .5rem;
    text-align: left;
    font-family: SFDisplay;
    color: #fff;
  }
  p{
    font-size: .36rem;
    line-height: .46rem;
    margin-bottom: .5rem;
    font-family: FuturaBook;
    color: #999999;
  }
  .subtitle{
    font-size: .36rem;
    line-height: .46rem;
    margin-bottom: .5rem;
    font-family: SFBold;
    color: #fff;
  }
  .contant{
    font-size: .36rem;
    line-height: .46rem;
    font-family: Futura144;
  }
  a{
    color: blue;
  }
}



@media (max-width: 576px) { 

  .service {
    .title{
      font-size: .36rem;
      line-height: .48rem;
      padding: .4rem 0 .16rem;
      margin-bottom: .26rem;
      position: sticky;
      top: 0;
      left: 0;
      background: #1A1A1A;
      width: 100%;
      text-align: center;
    }
    .date{
      font-size: .28rem;
      line-height: .38rem;
    }
    p{
      font-size: .24rem;
      line-height: .38rem;
      margin-bottom: .15rem;
    }
    .subtitle{
      font-size: .24rem;
      line-height: .38rem;
      margin-bottom: .15rem;
    }
  }
}